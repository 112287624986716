<template>
  <div class="container">
    <div class="citizen-service check-statusItem">
      <div class="citizen-item">
        <h2 class="citizen-title check-status">Murojaatingiz holatini tekshirish</h2>
        <h4 class="check-statusTitle">Murojaatingiz holati: </h4>
        <p class="citizen-text check-statusText">{{ status.status }}</p>
        <template v-if="status.file && status.file !== 'no file'">
          <h4 class="check-statusTitle">Murojaatingiz yakuni bo'yicha hujjat: </h4>
          <a href="#" @click.prevent=" downloadItem({ url: base_url+status.file, label: 'Yakuniy hujjat.pdf', }) ">
            <b class="check-statusTitle text-primary">
              Yakuniy hujjatni yuklab olish
            </b>
          </a>
        </template>
        <template v-if="status.revision_file && status.revision_file !== 'no file'">
          <h4 class="check-statusTitle">Murojaatingiz bo'yicha shakllangan buyruq: </h4>
          <a href="#" @click.prevent=" downloadItem({ url: base_url+status.revision_file, label: 'Buyruq.pdf', }) ">
            <b class="check-statusTitle text-primary">appointment
              Buyruqni yuklab olish
            </b>
          </a>
        </template>
        <p class="citizeSave" />
        <a href="/" class="btn homeBtn"><i class="lni-arrow-left" />&nbsp; Bosh sahifaga qaytish</a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    status: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    base_url() {
      return process.env.VUE_APP_BASE_URL
    }
  },
  methods: {
    async downloadItem({ url, label }) {
      const response = await axios.get(url, { responseType: 'blob' })
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = label
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>

<style>

</style>
